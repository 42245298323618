<template>
  <nav
    :class="{ 'px-4 pt-4 nav-w': open, 'w-0 p-0': !open }"
    class="flex flex-col bg-adminsecondary relative transition-all"
  >
    <MenuIcon
      v-if="!open"
      @click="open = !open"
      class="absolute -right-10 top-0 md:hidden z-40"
      :size="30"
    />
    <CloseIcon
      v-if="open"
      @click="open = !open"
      class="absolute right-2 top-0 md:hidden z-40"
      :size="30"
    />

    <!-- SideNavBar -->


    <div v-if="open" class="flex flex-row border-b items-center justify-between pb-4 mt-4">
      <!-- Hearder -->
      <img src="../../assets/admin/images/logo.svg" :alt="title" />
    </div>
    <router-link
      :to="{ name: 'home' }"
      class="lb-admin-primary-button-large mt-5"
      v-if="open"
      v-on:click="closeDropdown"
    >
      View Price List
    </router-link>
    <div v-if="open" v-on:click="closeDropdown">
      <ul class="mt-2 pb-10">
        <!-- Links -->
        <li v-if="hasAccess('dashboard', 'view')">
          <router-link :to="{ name: 'admin' }" class="lb-admin-main-nav-link">
            <DashboardIcon :size="30" />
            <p class="text-12">Dashboard</p>
          </router-link>
        </li>

        <!-- Units Section -->
        <li
          v-if="hasAccess('units', 'view')"
          class="lb-admin-man-nav-section-heading text-10"
        >
          Units
        </li>

        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-units' }"
            class="lb-admin-main-nav-link"
          >
            <UnitsIcon :size="30" />
            <p class="text-12">All</p>
          </router-link>
        </li>

        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-available-units' }"
            class="lb-admin-main-nav-link"
          >
            <UnitsIcon :size="30" />
            <p class="text-12">Available</p>
          </router-link>
        </li>

        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-pending-units' }"
            class="lb-admin-main-nav-link"
          >
            <UnitsIcon :size="30" />
            <p class="text-12">Pending</p>
          </router-link>
        </li>

        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-reserved-units' }"
            class="lb-admin-main-nav-link"
          >
            <UnitsIcon :size="30" />
            <p class="text-12">Reserved</p>
          </router-link>
        </li>
        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-sold-units' }"
            class="lb-admin-main-nav-link"
          >
            <UnitsIcon :size="30" />
            <p class="text-12">Sold</p>
          </router-link>
        </li>

        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-add-unit' }"
            class="lb-admin-main-nav-link"
          >
            <img
              src="/leadicons/add_circle_outline_white_24dp.svg"
              class="inline-block"
              style="padding: 2px"
            />
            <p class="text-12">Add unit</p>
          </router-link>
        </li>

        <!-- People Section -->
        <li class="lb-admin-man-nav-section-heading text-10">Clients</li>

        <li v-if="hasAccess('leads', 'view')">
          <router-link
            :to="{ name: 'admin-leads' }"
            class="lb-admin-main-nav-link"
          >
            <UsersIcon :size="30" />
            <p class="text-12">leads</p>
          </router-link>
        </li>

        <li v-if="hasAccess('users', 'view')">
          <router-link
            :to="{ name: 'admin-users' }"
            class="lb-admin-main-nav-link"
          >
            <UsersIcon :size="30" />
            <p class="text-12">Sign Ups</p>
          </router-link>
        </li>

        <li v-if="hasAccess('agents', 'view')">
          <router-link
            :to="{ name: 'admin-agents' }"
            class="lb-admin-main-nav-link"
          >
            <AgentsIcon :size="30" />
            <p class="text-12">Agents</p>
          </router-link>
        </li>
        <!-- Start of Shortlisted -->
        <li v-if="hasAccess('units', 'view')">
          <router-link
            :to="{ name: 'admin-shortlisted-units' }"
            class="lb-admin-main-nav-link"
          >
            <ShortlistIcon :size="30" />

            <p class="text-12">Unit Shortlists</p>
          </router-link>
        </li>
        <!--End-->
        <!-- Company Section -->
        <li
          v-if="hasAccess('settings', 'view')"
          class="lb-admin-man-nav-section-heading text-10"
        >
          Management
        </li>

        <li v-if="hasAccess('settings', 'view')">
          <router-link
            :to="{ name: 'admin-settings' }"
            class="lb-admin-main-nav-link"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">settings</p>
          </router-link>
        </li>
        <!--start of Unit Images-->
              <li v-if="hasAccess('settings', 'view')">
          <router-link
            :to="{ name: 'admin-images' }"
            class="lb-admin-main-nav-link"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">Unit Images</p>
          </router-link>
        </li>


        <li v-if="hasAccess('extraSettings', 'view')">
          <router-link
            :to="{ name: 'admin-extra-settings' }"
            class="lb-admin-main-nav-link"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">extra's</p>
          </router-link>
        </li>

        <li v-if="hasAccess('data', 'view')">
          <router-link
            :to="{ name: 'admin-data' }"
            class="lb-admin-main-nav-link"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">data export</p>
          </router-link>
        </li>

        <li v-if="hasAccess('email-templates', 'view')">
          <router-link
            :to="{ name: 'admin-email-templates' }"
            class="lb-admin-main-nav-link"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">email templates</p>
          </router-link>
        </li>

        <li v-if="hasAccess('admin-downloads', 'view')">
          <router-link
            class="lb-admin-main-nav-link"
            :to="{ name: 'admin-downloads' }"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">Manage Menu</p>
          </router-link>
        </li>

        <li v-if="hasAccess('attendants', 'view')">
          <router-link
            class="lb-admin-main-nav-link"
            :to="{ name: 'admin-attendants' }"
          >
            <SettingsIcon :size="30" />
            <p class="text-12">Social Chat</p>
          </router-link>
        </li>

        <!-- SideNavBar -->
        <li>
          <a @click="signOut" class="lb-admin-main-nav-logout">
          <span style="padding: 4px; display: inline-block; padding-right: 10px"
          ><LogoutIcon
          /></span>
            <span class="capitalize text-12">log out</span>
          </a>
        </li>
      </ul>
    </div>

  </nav>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import { profilesCollection } from "@/firebase";
import permissionsMixin from "../../mixins/permissions";
import AgentsIcon from "vue-material-design-icons/FaceAgent.vue";
import DashboardIcon from "vue-material-design-icons/ViewDashboard.vue";
import SettingsIcon from "vue-material-design-icons/Cog.vue";
import UsersIcon from "vue-material-design-icons/Account.vue";
import UnitsIcon from "vue-material-design-icons/Home.vue";
import LogoutIcon from "vue-material-design-icons/Logout.vue";
import MenuIcon from "vue-material-design-icons/Menu.vue";
import CloseIcon from "vue-material-design-icons/Close.vue";
import {APP_TITLE} from "@/Constants";
import ShortlistIcon from "vue-material-design-icons/Star.vue";


export default {
  name: "MainNavBarItems",
  mixins: [permissionsMixin],
  data() {
    return {
      title: APP_TITLE,
      windowWidth: window.innerWidth,
      open: true,
      role: "",
    };
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      profilesCollection.doc(user.uid).onSnapshot((snap) => {
        const user = snap.data();
        this.role = user.role;
      });
    });
  },
  components: {
    AgentsIcon,
    DashboardIcon,
    SettingsIcon,
    UsersIcon,
    UnitsIcon,
    LogoutIcon,
    MenuIcon,
    CloseIcon,
    ShortlistIcon
  },
  methods: {
    closeDropdown() {
      if(this.windowWidth <= 768) {
        return this.open = false;
      }
      return this.open = true;
    },
    // --- Code never been called ---
    gotToAddUnit() {
      this.$router.replace({ name: "admin-add-unit" });
    },
    signOut() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          if (this.$route.name !== "home") {
            this.$router.replace({
              name: "login",
            });
          } else {
            this.$router.replace({
              name: "login",
            });
          }
        });
    },
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
};
</script>

<style>
.lb-admin-main-nav-logout {
  color: #ffffff !important;
  border: none;
  justify-content: left;
  padding: 0;
}

.lb-admin-main-nav-logout:hover {
  background: transparent;
}

.text-12 {
  font-size: 12px !important;
}

.text-10 {
  font-size: 10px !important;
}

.nav-w {
  width: 15em;
}

</style>
