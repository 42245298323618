<template>
  <nav
    v-if="windowWidth <= 768"
    class="flex flex-col pb-12 bg-adminsecondary relative transition-all"
  >
    <MainNavBarItems />
  </nav>

  <nav
    v-else
    class="flex flex-col pb-12 bg-adminsecondary relative transition-all"
  >
    <MainNavBarItems />
  </nav>
</template>

<script>

import MainNavBarItems from "./MainNavBarItems.vue"

export default {
  name: "MainNavBar",
  components: {
    MainNavBarItems
  },
  data() {
    return {
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    window.onresize = () => {
      this.windowWidth = window.innerWidth
    };
  },
};
</script>

<style>

</style>
