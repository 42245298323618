<template>
  <div id="admin" class="w-full flex">
    <MainNavBar />
    <main
      class="flex flex-col w-full px-5 md:px-10 pt-10 pb-20 bg-gray-100 scroller-width"
    >
      <router-view />
    </main>
  </div>
</template>

<script>
import MainNavBar from "@/components/admin/MainNavBar.vue";

export default {
  name: "Admin",
  components: {
    MainNavBar,
  },
};
</script>

<style>
.scroller-width {
  overflow-y: scroll;
}
</style>
